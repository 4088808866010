<template>
  <div class="payments-modal" data-t="payments-modal">
    <Deposit v-if="selectedTabId === 'deposit'" />
    <Withdrawal v-if="selectedTabId === 'withdrawal'" />
  </div>
</template>

<script setup lang="ts">
import Deposit from '../Deposit/Deposit.vue'
import Withdrawal from '../Withdrawal/Withdrawal.vue'

const route = useRoute()

const selectedTabId = computed(() => route.query.tab?.toString() ?? 'deposit')
</script>

<style scoped>
.payments-modal {
  display: flex;
  flex-direction: column;
  width: 596px;
}
</style>
